import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import { Page } from "./types";
import PageHome from "containers/PageHome/PageHome";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import CheckoutCancel from "containers/CheckoutCancel";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import ListRooms from "containers/Iframe/ListRooms";

// Dont change the order of the pages
// There are some page are using them by index

export const pages: Page[] = [
  { path: "/", exact: true, component: ListingStayDetailPage },
  { path: "/iframe", exact: true, component: PageHome },
  { path: "/iframe#", exact: true, component: PageHome },
  { path: "/iframe/listRooms", exact: true,component: ListRooms},
  { path: "/details", exact: true, component: ListingStayDetailPage },
  { path: "/hotels", component: ListingStayMapPage },
  { path: "/hotel-detail/:hotelSlug", component: ListingStayDetailPage },
  { path: "/checkout/cancel", component: CheckoutCancel },
  { path: "/checkout", component: CheckOutPage },
  { path: "/reservations/:id/validate_konnect_payment", component: CheckOutPage },
];


