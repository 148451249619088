import { FC, useEffect, useMemo, useState } from "react";
import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { useSearchParam } from "react-use";
import { calculateStayTotalPrice } from "utils/prices.utils";
import { postInitBookingService, postSearchHotelsService } from "api.service";
import { useHistory, useParams } from "react-router-dom";
import { useFetchHotelDetails } from "hooks/useFetchHotelDetails";
import { useFetch } from "hooks/useFetch";
import { HotelSetting } from "interfaces/hotel";
import GuestsInput, {
  DEFAULT_ROOM_DATA,
} from "components/HeroSearchForm/GuestsInput";
import { useSearch } from "context/SearchContext";
import { useSearchResultFn } from "hooks/useSearchApi";
import { ISearchResult } from "interfaces/searchResult";
import { currentUrlParams } from "utils/getUrlParams";
import { IPostInitBookingServiceParams } from "interfaces/booking";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import StayCardH from "components/StayCardH/StayCardH";
import Preloader from "components/Preloader";
import Page404 from "../Page404/Page404";
import moment from "moment";
import NcDropDown from "shared/NcDropDown/NcDropDown";
import Header3 from "components/Header/Header3";
import BookingRooms from "containers/ListingDetailPage/BookingRooms";
import RoomCard from "containers/ListingDetailPage/RoomCard";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListRooms: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const hotelService = useSearchResultFn(postSearchHotelsService);
  const initBookingService = useSearchResultFn(postInitBookingService);
  const { hotelDetails, hotelDetailsLoading } = useFetchHotelDetails();

  const [selectedArrangements, setSelectedArrangements] =
    useState<ISelectedArrangements>({});
  const [selectedRooms, setSelectedRooms] = useState<{
    [roomIndex: number]: number /* number = roomTypeId */;
  }>({});
  const { hotelSlug }: { hotelSlug: string } = useParams();
  const {
    searchData,
    setSearchData,
    searchResult,
    setSearchResult,
    searchDestinations,
    getSearchPathWithQuery,
    myHotelInformation,
    userType,
    handleSearch,
  } = useSearch();

  const history = useHistory();

  const isOverBooked = useMemo(() => {
    return searchResult?.occupancies.reduce(
      (acc: any, val: any, index: any) => {
        const room = val.room_types.find(
          (el: any) => el.data.id === selectedRooms[index]
        );
        if (acc) return acc;
        if (!room) return true;
        if (!room.immediate_booking) return true;
        if (room.rate_type === "onrequest") return true;
        if (room?.quantity === 0 || room?.stop_sale) return true;
        return false;
      },
      false
    );
  }, [selectedRooms]);

  const isStopStale = useMemo(() => {
    return searchResult?.occupancies.reduce(
      (acc: any, val: any, index: any) => {
        const room = val.room_types.find(
          (el: any) => el.data.id === selectedRooms[index]
        );
        if (acc) return acc;
        if (!room) return true;
        if (room.rate_type === "stop_sale") return true;
        if (room?.stop_sale) return true;
        return false;
      },
      false
    );
  }, [selectedRooms]);

  useEffect(() => {
    if (searchResult?.occupancies.length !== 0) {
      const rooms = searchResult?.occupancies.reduce(
        (acc: any, val: any, index: any) => {
          const sortedByPrice = val.room_types.sort(
            (a: any, b: any) =>
              Number(a?.board?.[0].net) - Number(b?.board?.[0].net)
          );
          const firstSelectableRoomForSales = sortedByPrice.find(
            (el: any) => !el.stop_sale && el.quantity
          );
          const firstSelectableRoomOverbooked = sortedByPrice.find(
            (el: any) => !el.stop_sale && !el.quantity
          );
          const firstSelectableRoomStopsales = sortedByPrice.find(
            (el: any) => el.stop_sale
          );
          acc = {
            ...acc,
            [index]:
              firstSelectableRoomForSales?.data.id ||
              firstSelectableRoomOverbooked?.data.id ||
              firstSelectableRoomStopsales?.data.id,
          };

          return acc;
        },
        {}
      );
      setSelectedRooms(rooms);

      const arrangements = searchResult?.occupancies.reduce(
        (acc: any, val: any, index: any) => {
          acc = {
            ...acc,
            [index]: val.room_types.reduce((acc: any, roomType: any) => {
              acc = {
                ...acc,
                [roomType.data.id]: {
                  boardId: roomType.board[0].data.id,
                },
              };
              return acc;
            }, {}),
          };
          return acc;
        },
        {}
      );
      setSelectedArrangements(arrangements);
    }
  }, [searchResult]);

  useEffect(() => {
    const currentParams = currentUrlParams();
    if (currentParams.start) {
      const start_date = moment(currentParams.start);
      const end_date = moment(currentParams.end);
      const rooms = Array(Number(currentParams.rooms)).fill(DEFAULT_ROOM_DATA);

      setSearchData((prev: any) => {
        return {
          ...prev,
          dateRange: { startDate: start_date, endDate: end_date },
          rooms,
        };
      });

      const occupancies = rooms.map((room) => ({
        adult: room.guestAdults,
        child: room.guestChildren.length,
        child_ages: room.guestChildren,
      }));

      hotelService
        .execute({
          hotel: hotelSlug,
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD"),
          currency: searchData?.currency,
          occupancies,
        })
        .then((res: [ISearchResult]) => {
          setSearchResult(res[0]);
          setTimeout(() => {
            document
              .getElementById("search-room-result")
              ?.scrollIntoView({ behavior: "smooth" });
          }, 100);
        });
    }
  }, []);

  const handleInitBooking = async () => {
    const reservation: IPostInitBookingServiceParams = {
      hotel: hotelSlug,
      start_date: searchData.dateRange.startDate?.format("YYYY-MM-DD") || "",
      end_date: searchData.dateRange.endDate?.format("YYYY-MM-DD") || "",
      currency: searchData.currency,
      occupancies: searchData.rooms.reduce((acc: any, val, index) => {
        const occupancy = {
          adult: val.guestAdults,
          child: String(val.guestChildren.length),
          child_ages: val.guestChildren,
          room_id: String(selectedRooms[index]),
          board_id: String(
            selectedArrangements[index][selectedRooms[index]].boardId
          ),
        };

        acc.push(occupancy);
        return acc;
      }, []),
    };

    initBookingService.execute(reservation).then((res: any) => {
      history.push("/checkout?checkoutSlug=" + res.slug);
    });
  };

  const onSelectRoomType = (roomIndex: number) => (roomTypeId: number) => {
    setSelectedRooms((v) => ({
      ...v,
      [roomIndex]: roomTypeId,
    }));
  };

  const getSearchLink = () => {
    if (
      searchDestinations.hotels.find((el) => el.slug === searchData.hotel_slug)
    ) {
      return getSearchPathWithQuery("/iframe/listRooms/" + searchData.hotel_slug);
    } else if (myHotelInformation?.slug) {
      return getSearchPathWithQuery(
        "/iframe/listRooms/" + myHotelInformation?.slug
      );
    } else {
      return getSearchPathWithQuery("/iframe/listRooms");
    }
  };

  const calculateTotalPrice = () => {
    // debugger;
    return calculateStayTotalPrice(
      userType!,
      searchResult?.occupancies!,
      selectedRooms,
      selectedArrangements
    );
  };

  const handleSearchClick = () => {
    handleSearch().then(() => {
      document
        .getElementById("search-room-result")
        ?.scrollIntoView({ behavior: "smooth" });
      window.history.replaceState(null, "Maison Kinko", getSearchLink());
    });
  };

  const renderSidebar = () => {
    const renderTrigger = () => {
      return (
        <div className="text-start px-8 h-full w-full py-3">
          <span className="block font-semibold">Devise</span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {searchData.currency}
          </span>
        </div>
      );
    };
  
    const renderItem = (item: { id: string; name: string; icon: string }) => {
      return (
        <div
          className="py-2 px-4 hover:bg-gray-100 rounded-md"
          onClick={() =>
            setSearchData((prev: any) => ({ ...prev, currency: item.name }))
          }
        >
          {item.name}
        </div>
      );
    };
  
    return (
      <div className="listingSectionSidebar__wrap shadow-xl" style={{ maxHeight: '100vh'}}>
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl text-black">
          <StayDatesRangeInput
            defaultValue={searchData.dateRange}
            onChange={(e) => {
              setSearchData({ ...searchData, dateRange: e });
            }}
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            fieldClassName="p-3"
            anchorDirection={"right"}
            className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1 "
          />
          <GuestsInput
            className="nc-ListingStayDetailPage__guestsInput flex-1 border-y"
            fieldClassName="p-3"
            rooms={searchData.rooms}
            onChange={(e) => {
              setSearchData({ ...searchData, rooms: e });
            }}
            defaultValue={{}}
            hasButtonSubmit={false}
          />
          <div className="flex items-center">
            <div className="relative left-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 640 512"
                fill="#999"
              >
                <path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32z" />
              </svg>
            </div>
            <NcDropDown
              data={[
                { id: "TND", name: "TND", icon: "" },
                { id: "EUR", name: "EUR", icon: "" },
                { id: "USD", name: "USD", icon: "" },
              ]}
              renderTrigger={renderTrigger}
              renderItem={renderItem}
              className="flex-1"
            />
          </div>
        </form>
        <ButtonPrimary className="w-full text-sm" onClick={handleSearchClick}>
          Rechercher
        </ButtonPrimary>
  
        {searchResult &&
          !(searchResult?.occupancies.length === 1 &&
            searchResult?.occupancies[0].room_types.length === 0) && (
            <>
              <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between items-center">
                <div className="text-lg font-bold mb-2">Total</div>
                <div className="flex items-end flex-col">
                  {calculateTotalPrice().price_without_promotion !==
                    calculateTotalPrice().price && (
                    <div className="text-red-600 text-sm line-through">
                      {calculateTotalPrice().price_without_promotion}{" "}
                      {searchData?.currency}
                    </div>
                  )}
                  <div className="font-bold text-xl">
                    {calculateTotalPrice().price} {searchData?.currency}
                  </div>
                </div>
              </div>
            </>
          )}
  
        {!isStopStale &&  searchResult &&  (
          <ButtonPrimary
            className="sticky bottom-4 left-0 right-0 z-20 w-full !bg-green-700"
            onClick={handleInitBooking}
            loading={initBookingService.loading}
          >
            <div className="text-sm">
              {!isOverBooked ? "Réserver" : "Demande de réservation"}
            </div>
          </ButtonPrimary>
        )}
      </div>
    );
  };
  

  const Booking = () => {
    return (
      <div className="listingSection__wrap lg:border-b border-b-0 p-0 border-none">
        {searchResult?.occupancies?.map((room: any, index: any) => (
          <div key={index}>
            <h2 className="text-primary-blue font-bold capitalize lg:text-xl text-base">
              Chambre {index + 1}
            </h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-5" />
            {room.room_types.length !== 0 ? (
              <BookingRooms
                isHotelDetails
                hotelDetails={hotelDetails}
                selectedRoomType={selectedRooms?.[index]}
                onSelectRoomType={onSelectRoomType(index)}
                roomIndex={index}
                selectedArrangements={selectedArrangements}
                setSelectedArrangements={setSelectedArrangements}
                roomTypes={room.room_types}
              />
            ) : (
              <div className="text-base text-red-500">
                Aucune chambre disponible correspondante à votre recherche.
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderHotelRoomsSection = () => {
    return (
      <section id="rooms_available" className="listingSection__wrap">
        <h2 className="lg:text-lg sm:text-sm text-sm text-primary-blue font-bold capitalize ">
          Les Chambres
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {hotelDetails?.room_types?.map((el: any, index: number) => (
              <div className="" key={index}>
                <RoomCard
                  roomIndex={index}
                  hotelDetails={hotelDetails}
                  isHotelDetails
                  isStopSales={false}
                  setSelectedArrangements={setSelectedArrangements}
                  selectedArrangements={selectedArrangements}
                  className="mb-4"
                  badgeDisplay={false}
                  room={{
                    ...el,
                    board: [],
                    data: { id: el.id, name: el.name },
                    stop_sale: false,
                    rate_type: el.rate_type,
                    immediate_booking: true,
                    quantity: 1,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  const hotelName =
    hotelSlug &&
    (searchDestinations.hotels.find((el) => el.slug === hotelSlug)?.name ||
      hotelDetails?.name);

  if (hotelDetailsLoading) return <Preloader />;
  if (!hotelDetails) return <Page404 />;

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="lg:w-[70%]  space-y-8 lg:space-y-10 lg:pr-10 pb-20">
          <StayCardH
            isHotelDetails
            hotel={{ ...searchResult!, data: hotelDetails! }}
          />
           <div className="lg:hidden my-5 flex flex-grow justify-center">
           <Header3 destinationName={hotelName} isOnlySearch />
          </div>
          <div id="search-room-result">{!searchResult ? renderHotelRoomsSection() : <Booking />}</div>-
     
        </div>

        {/* SIDEBAR */}
        <div className="lg:w-[30%] hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-3">{renderSidebar()}</div>
        </div>
      </main>

  
    </div>
  );
};

export default ListRooms
