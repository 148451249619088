import { FC, useMemo, useState } from "react";
import { ISearchResult, IRoomType } from "interfaces/searchResult";
import { useSearch } from "context/SearchContext";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import { calculateRoomTotalPrice, calculateStayTotalPrice } from "utils/prices.utils";

const COLUMNS: {
  id: "roomType" | "pensionType" | "totalNight";
  title: string;
}[] = [
    {
      id: "roomType",
      title: "Type de chambre",
    },
    {
      id: "pensionType",
      title: "Arrangement",
    },
    {
      id: "totalNight",
      title: "Prix",
    },
  ];

type TStayCardPricesTable = {
  columns?: {
    id: "roomType" | "pensionType" | "totalNight";
    title: string;
  }[];
  data: ISearchResult["occupancies"];
  className?: string;
};

export interface ISelectedArrangements {
  [roomIndex: number]: {
    [roomTypeId: number]: {
      boardId: number;
    };
  };
}

const DisplayRoom: FC<{
  isChecked?: boolean;
  isStopSales?: boolean;
  selectedRoomType?: number;
  onSelectRoomType: (roomType: number, isOverBooked: boolean) => void;
  room: ISearchResult["occupancies"][0]["room_types"][0];
  roomIndex: number;
  index: number;
  selectedArrangements: ISelectedArrangements;
  setSelectedArrangements: (data: ISelectedArrangements) => void;
}> = ({
  isStopSales,
  isChecked,
  onSelectRoomType,
  setSelectedArrangements,
  room,
  selectedArrangements,
  roomIndex,
  index,
}) => {
    const { userType, searchData } = useSearch();
    const calculatedPrice = calculateRoomTotalPrice(userType!, room,
        selectedArrangements[roomIndex][room.data.id].boardId,
    );

    return (
      <tbody>
        <tr className={`${index % 2 !== 0 ? "bg-gray-50" : ""} `}>
          <td className='px-2 py-2 '>
            <div className='flex items-center pl-2'>
              <Checkbox
                checked={isChecked}
                className='lg:text-sm'
                onChange={() => onSelectRoomType(room.data.id, room.quantity <= 0)}
                name={`${room.data.id}${room.data.id}${roomIndex}`}
                label={"1 x " + room.data.name}
                onlyLabel={isStopSales}
                inputClassName='w-4 h-4'
              />
              {isStopSales && (
                <div
                  className={`ml-1 text-xs font-bold  w-max py-1 px-4 rounded-xl text-white ${"bg-red-500 "} `}>
                  {"Stop sales"}
                </div>
              )}
              {room.quantity <= 0 && <Badge className='ml-2' name='Sur demande' color='yellow' />}
            </div>
          </td>
          <td className='px-2 py-4'>
            <Select
              className='w-fit'
              onChange={e =>
                setSelectedArrangements({
                  ...selectedArrangements,
                  [roomIndex]: {
                    ...(selectedArrangements[roomIndex] || {}),
                    [room.data.id]: {
                      ...((selectedArrangements[roomIndex] &&
                        selectedArrangements[roomIndex][room.data.id]) ||
                        {}),
                      boardId: Number(e.target.value),
                    },
                  },
                })
              }>
              {room.board.map((el, idx) => (
                <option value={el.data.id} key={idx}>
                  {el.data.name}
                </option>
              ))}
            </Select>
          </td>
          <td className='px-2'>
            <div
              className='' /*onMouseEnter={() => setIsPriceTooltip(true)} onMouseLeave={() => setIsPriceTooltip(false)}*/
            >
              {calculatedPrice.price - calculatedPrice.price_without_promotion !== 0 && (
                <div className='text-center line-through text-xs text-red-600 '>
                  {calculatedPrice.price_without_promotion} {searchData?.currency}
                </div>
              )}
              <div className='text-center font-semibold'>{calculatedPrice.price} {searchData?.currency}</div>
            </div>
          </td>
        </tr>
      </tbody>
    );
  };

const Table: FC<
  Omit<TStayCardPricesTable, "data"> & {
    selectedRoomType?: number;
    onSelectRoomType: (roomType: number, isOverBooked: boolean) => void;
    roomIndex: number;
    selectedArrangements: ISelectedArrangements;
    setSelectedArrangements: (data: ISelectedArrangements) => void;
    roomTypes: ISearchResult["occupancies"][0]["room_types"];
  }
> = ({
  columns,
  selectedRoomType,
  onSelectRoomType,
  roomIndex,
  roomTypes,
  setSelectedArrangements,
  selectedArrangements,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const stopSalesRooms = roomTypes.filter(el => el.stop_sale);
    const forSalesRooms = roomTypes.filter(el => !el.stop_sale);

    return (
      <table className='w-full text-left hidden lg:table '>
        <thead>
          <tr className='bg-gray-100 '>
            {columns?.map((el, idx) => (
              <th
                className={`text-sm font-semibold px-2 py-2 ${el.id === "totalNight" ? "text-center" : ""
                  } ${el.id === "roomType" ? "pl-4" : ""} `}
                key={el.id}>
                {el.title}
              </th>
            ))}
          </tr>
        </thead>

        {forSalesRooms.map((e, index) => (
          <DisplayRoom
            isChecked={selectedRoomType === e.data.id}
            key={e.data.id}
            selectedRoomType={selectedRoomType}
            onSelectRoomType={onSelectRoomType}
            roomIndex={roomIndex}
            room={e}
            selectedArrangements={selectedArrangements}
            setSelectedArrangements={setSelectedArrangements}
            index={index}
          />
        ))}
        {stopSalesRooms[0] && (
          <DisplayRoom
            isStopSales
            selectedRoomType={selectedRoomType}
            onSelectRoomType={onSelectRoomType}
            roomIndex={roomIndex}
            room={stopSalesRooms[0]}
            selectedArrangements={selectedArrangements}
            setSelectedArrangements={setSelectedArrangements}
            index={forSalesRooms.length}
          />
        )}
        {stopSalesRooms.length > 1 && !isExpanded && (
          <tr>
            <td colSpan={4}>
              <button
                className='w-full text-3xl rotate-90 h-full'
                onClick={() => setIsExpanded(true)}>
                {">"}
              </button>
            </td>
          </tr>
        )}

        {isExpanded &&
          stopSalesRooms
            .splice(1)
            .map((e, index) => (
              <DisplayRoom
                key={e.data.id}
                isStopSales
                selectedRoomType={selectedRoomType}
                onSelectRoomType={onSelectRoomType}
                roomIndex={roomIndex}
                room={e}
                selectedArrangements={selectedArrangements}
                setSelectedArrangements={setSelectedArrangements}
                index={index}
              />
            ))}
      </table>
    );
  };

const RoomsMobile: FC<{
  selectedRoomType: number;
  onSelectRoomType: (roomType: number, isOverBooked: boolean) => void;
  selectedArrangements: ISelectedArrangements;
  roomIndex: number;
  setSelectedArrangements: (data: ISelectedArrangements) => void;
  roomTypes: ISearchResult["occupancies"][0]["room_types"];
}> = ({
  selectedRoomType,
  onSelectRoomType,
  selectedArrangements,
  roomIndex,
  setSelectedArrangements,
  roomTypes,
}) => {
    const { userType, searchData } = useSearch();

    const calculateRoomPrice = (room: IRoomType) => {
      return calculateRoomTotalPrice(
        userType!,
        room,
          selectedArrangements[roomIndex][room.data.id].boardId,

      );
    };

    return (
      <>
        {roomTypes.map((room, index) => (
          <div className='block lg:hidden pb-5 mb-3 border-b ' key={index}>
            <div className={`${index % 2 !== 0 ? "bg-gray-50" : ""} `}>
              <div className='px-2 py-2 '>
                <div className='flex flex-wrap'>
                  <Checkbox
                    onChange={() => onSelectRoomType(room.data.id, room.quantity <= 0)}
                    checked={selectedRoomType === room.data.id}
                    name={room.data.name}
                    label={room.data.name}
                  />

                  {room.quantity <= 0 && (
                    <Badge className='ml-2' name='Sur demande' color='yellow' />
                  )}
                  {/* <div className={` mt-1 text-[10.5px] font-bold  w-max py-1 px-4 rounded-xl text-white ${roomType.tagType === "error" ? "bg-red-500 " : "bg-orange-500"} `}>
                                        {roomType.tagText}
                                    </div> */}
                </div>
                {/* <div className="mt-1 text-sm text-green-900">
                                    Annulation gratuite avant le {moment(roomType.cancellationBefore).format("YYYY-MM-DD")}
                                </div> */}
              </div>
              <div className=''></div>
              <div className='flex items-center justify-between'>
                <div className='px-2'>
                  <Select
                    onChange={e =>
                      setSelectedArrangements({
                        ...selectedArrangements,
                        [roomIndex]: {
                          ...(selectedArrangements[roomIndex] || {}),
                          [room.data.id]: {
                            ...((selectedArrangements[roomIndex] &&
                              selectedArrangements[roomIndex][room.data.id]) ||
                              {}),
                            boardId: Number(e.target.value),
                          },
                        },
                      })
                    }>
                    {room.board.map((el, idx) => (
                      <option value={el.data.id} key={idx}>
                        {el.data.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className='px-2'>
                  {calculateRoomPrice(room).price -
                    calculateRoomPrice(room).price_without_promotion !==
                    0 && (
                      <div className='text-center line-through text-xs text-red-600 '>
                        {calculateRoomPrice(room).price_without_promotion} {searchData?.currency}
                      </div>
                    )}
                  <div className='text-center font-semibold'>
                    {calculateRoomPrice(room).price} {searchData?.currency}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

const StayCardPricesTable: FC<
  TStayCardPricesTable & {
    selectedArrangements: ISelectedArrangements;
    setSelectedArrangements: (data: ISelectedArrangements) => void;
    selectedRooms: {
      [roomIndex: number]: number;
    };
    setSelectedRooms: (data: { [roomIndex: number]: number }) => void;
    hotelSlug?: string;
    handleInitBooking: () => void;
    initBookingLoading: boolean;
  }
> = ({
  columns = COLUMNS,
  data,
  className,
  selectedArrangements,
  setSelectedArrangements,
  selectedRooms,
  setSelectedRooms,
  handleInitBooking,
  initBookingLoading,
  hotelSlug,
}) => {
    const { userType, searchData } = useSearch();
    const [overBookedData, setOverBookedData] = useState<Record<number, boolean>>({});
    const onSelectRoomType = (roomIndex: number) => (roomTypeId: number, isOverBooked: boolean) => {
      setOverBookedData(v => ({
        ...v,
        [roomIndex]: isOverBooked,
      }));
      setSelectedRooms({
        ...selectedRooms,
        [roomIndex]: roomTypeId,
      });
    };

    const isOverBooked = useMemo(() => {
      return data.reduce((acc, val, index) => {
        const arrangement = val.room_types.find(el => el.data.id === selectedRooms[index]);
        if (acc === true) return acc;
        if (arrangement?.quantity === 0 || arrangement?.stop_sale) return true;
        return acc;
      }, false);
    }, [selectedRooms]);

    const calculateTotalPrice = () => {
      return calculateStayTotalPrice(userType!, data, selectedRooms);
    };

    return (
      <div className={`${className}`}>
        {data.map((room, index) => (
          <div className='' key={index}>
            <div className='p-3 font-bold'>
              Chambre {index + 1}
              {/* {Array.from({length: room.pax.adult}, () => <i className="las la-user text-xl ml-[-5px]"></i>)} {Array.from({length: room.pax.adult}, () => <i className="text-xl ml-[-5px] las la-child"></i>)} */}
            </div>
            {/* mobile block */}
            <RoomsMobile
              selectedRoomType={selectedRooms?.[index]}
              onSelectRoomType={onSelectRoomType(index)}
              roomIndex={index}
              selectedArrangements={selectedArrangements}
              setSelectedArrangements={setSelectedArrangements}
              roomTypes={room.room_types}
            />
            {/* large screen table */}
            <Table
              selectedRoomType={selectedRooms?.[index]}
              onSelectRoomType={onSelectRoomType(index)}
              roomIndex={index}
              selectedArrangements={selectedArrangements}
              setSelectedArrangements={setSelectedArrangements}
              roomTypes={room.room_types}
              columns={columns}
            />
          </div>
        ))}
        {/* footer */}
        <div className='py-3 px-2 flex lg:justify-end lg:items-center justify-between'>
          <div className='text-sm'>Montant total du séjour :</div>
          <div className='flex lg:items-center flex-col lg:flex-row items-end '>
            <div className='font-bold lg:text-xl text-xl lg:mx-3 flex items-center '>
              {calculateTotalPrice().price !== calculateTotalPrice().price_without_promotion && (
                <div className='text-sm line-through text-red-600 mr-2 font-normal'>
                  {calculateTotalPrice().price_without_promotion} <span className=''>{searchData?.currency}</span>
                </div>
              )}
              <div className=''>
                {calculateTotalPrice().price} <span className='text-sm'>{searchData?.currency}</span>
              </div>
            </div>
            <ButtonPrimary
              loading={initBookingLoading}
              onClick={handleInitBooking}
              className='!bg-green-700 mt-3 z-10'>
              <span className='text-xs p-5 capitalize'>
                {!isOverBooked ? "Réserver" : "Demande de réservation"}
              </span>
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  };

export default StayCardPricesTable;
