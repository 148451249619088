import React from "react";
import { IHotelDetails } from "interfaces/hotel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import visa from "../../images/payment/visa.png";
import mastercard from "../../images/payment/mastercard.png";
import d17 from "../../images/payment/d17.png";
import flouci from "../../images/payment/flouci.png";
import konnect from "../../images/payment/konnect.png";
import GeneralCondtions from "./ModalGeneralConditions";
import useWindowSize from "hooks/useWindowResize";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC<{ hotelDetails: IHotelDetails }> = ({
  hotelDetails,
}) => {
  const windowSize = useWindowSize();
  const isSmallScreen = windowSize.width < 580;

  const emailList = hotelDetails.emails.split(",").map((email) => email.trim());

  const handleEmailClick = (email: string) => {
    window.open(`mailto:${email}`, "_blank");
  };

  
  const phones = hotelDetails?.phones;
  console.log(phones,'phones')

  // Function to format phone numbers
  function formatPhones(phones: IHotelDetails['phones']) {
    if (typeof phones === "string") {
      // If it's a string, return it as is
      return phones;
    } else if (Array.isArray(phones)) {
        // If it's an array, check its length
        if (phones.length === 1) {
          // If the array contains only one element, return that element
          return phones[0];
        } else {
          return phones.map(phone => phone.trim()).join(", ");
        }
    } else {
      // If it's neither a string nor an array, return an empty string
      return "";
    }
  }

  const formattedPhones = formatPhones(phones);

  console.log(formattedPhones,'formattedPhones')

  return (
    <footer className="bg-white text-gray-800">
      <div className="py-6 px-12">
        <div className="flex flex-wrap justify-between">
          {/* Logo and Company Info */}
          <div
            className={`w-full lg:w-5/12 mb-10 lg:mb-0 ${
              isSmallScreen ? "text-center" : ""
            }`}
          >
            <div className={`${isSmallScreen ? "flex justify-center" : ""}`}>
              <img
                src={hotelDetails.logo}
                alt="Site Logo"
                className="h-14 mb-4"
                loading="lazy"
              />
            </div>
            <div className="text-xs">
              <div>
                Réservez sur le Site Officiel de {hotelDetails.name} | Meilleur
                Prix Garanti
              </div>
              <div className="text-xs fw-500 mt-5">
                Paiement en ligne sécurisé
              </div>
              <br />
              <div className="flex w-full space-x-4 h-4">
                <img src={visa} alt="Visa" loading="lazy" />
                <img src={mastercard} alt="MasterCard" loading="lazy" />
                <img src={d17} alt="D17" loading="lazy" />
                <img src={flouci} alt="Flouci" loading="lazy" />
                <img src={konnect} alt="Konnect" loading="lazy" />
              </div>
              <br />
            </div>
          </div>
          {/* Quick Links */}
          <div
            className={`w-full lg:w-3/12 mb-10 lg:mb-0 ${
              isSmallScreen ? "text-center" : ""
            }`}
          >
            <h3 className="font-semibold text-sm mb-4">Liens Rapides</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="#rooms_available"
                  className="text-xs hover:text-primary-blue"
                >
                  Chambres
                </a>
              </li>
              <li>
                <a
                  href="#services_equipment"
                  className="text-xs hover:text-primary-blue"
                >
                  Services et équipements
                </a>
              </li>
              <li>
                <a
                  href="#accommodation"
                  className="text-xs hover:text-primary-blue"
                >
                  Hébergement
                </a>
              </li>
              <li>
                <a
                  href="#general_infos"
                  className="text-xs hover:text-primary-blue"
                >
                  Informations générales
                </a>
              </li>
              <GeneralCondtions />
            </ul>
          </div>
          {/* Contact Info */}
          <div
            className={`w-full lg:w-4/12 mb-10 lg:mb-0 ${
              isSmallScreen ? "text-center" : ""
            }`}
          >
            <h3 className="font-semibold text-sm mb-4">Contactez-Nous</h3>
            <ul className="space-y-2 text-xs">
              <li className="space-x-2">
                <FontAwesomeIcon icon={faEnvelope} />
                {emailList.map((email, index) => (
                  <React.Fragment key={index}>
                    <button
                      onClick={() => handleEmailClick(email)}
                      className="hover:text-black text-blue-900 bg-transparent border-0 p-0 cursor-pointer"
                    >
                      {email}
                    </button>
                    {index < emailList.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </li>
              <li className="space-x-2">
                <FontAwesomeIcon icon={faPhone} />
                <span>{formattedPhones}</span>
              </li>
              <li className="space-x-2">
                <FontAwesomeIcon icon={faMapPin} />
                <span>
                  {hotelDetails.name}, {hotelDetails.address},{" "}
                  {hotelDetails.city.city}, Tunisie
                </span>
              </li>
            </ul>
            <div className="mt-3 space-x-3">
              <a
                href={hotelDetails.facebook_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} className="text-xl" />
              </a>
              <a
                href={hotelDetails.instagram_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className="text-xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom bg-secondary-gray">
        <p className="text-black text-center fw-500 text-sm">
          © All Rights Reserved - Made with ❤️ by{" "}
          <a href="https://kinkobooking.com/">Kinko Booking</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;