import { IRoom } from "components/HeroSearchForm/GuestsInput";
import { useSearch } from "context/SearchContext";
import React, { useState } from "react";
import { addLetterSofPlural } from "utils/string";
import GuestsInput from "./GuestsInput";
import LocationInput from "./LocationInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import NcDropDown from "shared/NcDropDown/NcDropDown";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StaySearchForm = () => {
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "guests"
  >("location");
  const { searchData, setSearchData, userType } = useSearch();
  const { location, dateRange, rooms } = searchData;

  const renderInputLocation = () => {
    const isActive = fieldNameShow === "location";
    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {userType === "Agency" && (
          <LocationInput
            isActive={isActive}
            defaultValue={location}
            setFieldNameShow={setFieldNameShow}
            onChange={(location, hotel_slug) => {
              setSearchData({ ...searchData, location, hotel_slug });
              setFieldNameShow("dates");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";
    const startDateString = dateRange.startDate?.format("MMM DD");
    const endDateString =
      dateRange.endDate?.get("month") !== dateRange.startDate?.get("month")
        ? dateRange.endDate?.format("MMM DD")
        : dateRange.endDate?.format("DD");
    const dateSelected =
      startDateString && endDateString
        ? `${startDateString} - ${endDateString}`
        : `${startDateString || endDateString || ""}`;
    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">Séjour</span>
            <span>{dateSelected || "Add date"}</span>
          </button>
        ) : (
          <StayDatesRangeInput
            defaultValue={dateRange}
            onChange={(data) => {
              setSearchData({ ...searchData, dateRange: data });
              if (!dateRange.endDate && data.endDate)
                setFieldNameShow("guests");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputGuests = () => {
    const isActive = fieldNameShow === "guests";

    return (
      <div
        className={`w-full bg-white dark:bg-primary-gray overflow-hidden ${
          isActive
            ? "rounded-2xl shadow-lg"
            : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
        }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("guests")}
          >
            <span className="text-neutral-400">Chambre</span>
            <span>{`${rooms.length} Chambre${addLetterSofPlural(
              rooms.length
            )} `}</span>
          </button>
        ) : (
          <GuestsInput
            rooms={rooms}
            onChange={(data: IRoom[]) => {
              setSearchData({ ...searchData, rooms: data });
            }}
          />
        )}
      </div>
    );
  };

  const renderDevice = () => {
    const renderTrigger = () => {
      return (
        <div className="p-4">
          <div className="flex justify-between">
            <span className="text-neutral-400">      
              <FontAwesomeIcon icon={faMoneyBill} className="text-gray-400 me-2" />
              Devise
              </span>
            <span className="mt-1 text-sm leading-none font-semibold">
              {searchData.currency}
            </span>
          </div>
        </div>
      );
    };

    const renderItem = (item: { id: string; name: string; icon: string }) => {
      return (
        <div
          className="py-2 px-4 hover:bg-gray-100 rounded-md"
          onClick={() =>
            setSearchData((prev: any) => ({ ...prev, currency: item.name }))
          }
        >
          {item.name}
        </div>
      );
    };

    return (
      <div className="w-full bg-white dark:bg-primary-gray rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]">
        <NcDropDown
          data={[
            { id: "TND", name: "TND", icon: "" },
            { id: "EUR", name: "EUR", icon: "" },
            { id: "USD", name: "USD", icon: "" },
          ]}
          renderTrigger={renderTrigger}
          renderItem={renderItem}
          className="flex-1"
        />
      </div>
    );
  };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocation()}
        {renderInputDates()}
        {renderInputGuests()}
        {renderDevice()}
      </div>
    </div>
  );
};

export default StaySearchForm;
