import React, { FC, useEffect, useState } from "react";
import StaySearchForm, { StaySearchFormFields } from "./StaySearchForm";

export type SearchTab = "Stays" | "Experiences" | "Cars" | "Flights";

export interface HeroSearchFormProps {
  className?: string;
  defaultTab?: SearchTab;
  onTabChange?: (tab: SearchTab) => void;
  defaultFieldFocus?: StaySearchFormFields;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  defaultTab = "Stays",
  onTabChange,
  defaultFieldFocus,
}) => {
  const [tabActive, setTabActive] = useState<SearchTab>(defaultTab);

  useEffect(() => {
    if (defaultTab === tabActive) {
      return;
    }
    setTabActive(defaultTab);
  }, [defaultTab]);

  return (
    <div
      className={`nc-HeroSearchForm ${className}`}
      data-nc-id="HeroSearchForm"
    >
      <div>
        <StaySearchForm defaultFieldFocus={defaultFieldFocus} />
      </div>
    </div>
  );
};

export default HeroSearchForm;
